import React, { useEffect, useState } from 'react';
import {
	Table,
	SingleSelect,
	Loader,
	Container,
	Heading,
} from './../../../@core-ui';
import {
	getDeviceType,
	handleWindowResize,
	sortByKey,
} from '../../../utilities/utils';
import {
	E_DeviceType,
	T_DeviceType,
	SORTING_DIRECTION,
} from '../../../constants/appConstants';
import {
	StatisticsColumns,
	StatisticsIndicesDropdownConstants,
	IStatisticsTable,
	StatisticsXidMapping,
	StatisticsMobileViewColumns,
	IStatisticsMobileTable,
} from './statistics-constants';
import { getQuotes } from '../../../services';
import {
	formatDateTime,
	DATE_FORMATS,
} from '../../../utilities/date-time-formatter';
import { IQuoteResponse } from '../../../types/interfaces';
import './statistics.scss';
import { getNewDate } from '../../../utilities/date-time-formatter-tz';
import { ISortingInfo } from '../../../@core-ui/Table/table';

const Statistics: React.FC = () => {
	const [sortingInfo, setSortingInfo] = useState<ISortingInfo>();
	const [statisticsTableData, setStatisticsTableData] = useState<
		IStatisticsTable[]
	>([]);
	const [statisticsMobileTableData, setStatisticsMobileTableData] = useState<
		IStatisticsMobileTable[]
	>([]);
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const isMobileView = device === E_DeviceType.Mobile;
	const [loadingState, setLoadingState] = useState({
		isLoading: true,
	});
	const [quoteResponse, setQuoteResponse] = useState<IQuoteResponse>();
	const [selectedIndexForMobile, setSelectedIndexForMobile] = useState(
		StatisticsIndicesDropdownConstants[0].key,
	);

	const currentDate = formatDateTime(getNewDate(), {
		format: DATE_FORMATS.MONTH_DATE_YEAR_FORMAT,
	});

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};

		handleWindowResize(handleResize);
		fetchData();
	}, []);

	useEffect(() => {
		if (quoteResponse) {
			if (isMobileView) {
				fetchStatisticsTableDataForMobile(selectedIndexForMobile);
				return;
			}
			fetchStatisticsTableData();
		}
	}, [quoteResponse, selectedIndexForMobile]);

	const fetchData = async () => {
		setLoadingState({ isLoading: true });
		const xids = StatisticsXidMapping.map((x) => x.xid);
		const response = await getQuotes(xids);
		setQuoteResponse(response);
		setLoadingState({ isLoading: false });
	};

	const fetchStatisticsTableData = async () => {
		let index = 0;
		const data: IStatisticsTable[] = StatisticsIndicesDropdownConstants.map(
			(x) => {
				const row = quoteResponse?.data?.data?.quotes;
				return {
					Index: x.key,
					NewHighs: row?.[index++].data?.lastTrade?.last,
					NewLows: row?.[index++].data?.lastTrade?.last,
					AdvancingIssues: row?.[index++].data?.lastTrade?.last,
					DecliningIssues: row?.[index++].data?.lastTrade?.last,
					UnchangingIssues: row?.[index++].data?.lastTrade?.last,
				};
			},
		);
		setStatisticsTableData(data);
		const defaultSorting: ISortingInfo = {
			sortedColumn: 'Index',
			sortDir: 'asc',
		};
		setSortingInfo(defaultSorting);
	};

	const fetchStatisticsTableDataForMobile = async (indexName: string) => {
		let index = 0;
		const xids = StatisticsXidMapping.filter(
			(x) => x.indexName === indexName,
		).map((x) => x.xid);
		const quotesForIndex = quoteResponse?.data?.data?.quotes.filter((x) => {
			return xids.includes(x.data?.venueXid);
		});
		const data: IStatisticsMobileTable[] = StatisticsColumns.filter(
			(x) => x.accessor !== 'Index',
		).map((x) => {
			return {
				IndexKey: x.header,
				IndexValue: quotesForIndex?.[index++]?.data?.lastTrade?.last,
			};
		});
		setStatisticsMobileTableData(data);
	};

	const processData = (item: any) => {
		const indicesObj = {} as IStatisticsTable;
		indicesObj.Index = item.Index;
		indicesObj.NewHighs = item.NewHighs;
		indicesObj.NewLows = item.NewLows;
		indicesObj.AdvancingIssues = item.AdvancingIssues;
		indicesObj.DecliningIssues = item.DecliningIssues;
		indicesObj.UnchangingIssues = item.UnchangingIssues;
		return indicesObj;
	};

	const settingTableResult = (
		data: IStatisticsTable[],
		colName: string,
		dir: SORTING_DIRECTION,
	) => {
		const sortingData = [...data].sort(sortByKey(colName, dir));
		const content: IStatisticsTable[] = [];
		sortingData.forEach((item) => {
			content.push(processData(item));
		});
		setStatisticsTableData(content);
	};

	function sortDataHandler(colName: string, sortDirection: SORTING_DIRECTION) {
		setSortingInfo({
			sortedColumn: colName,
			sortDir: sortDirection,
		});
		settingTableResult(statisticsTableData, colName, sortDirection);
	}
	const handleIndexChange = async (event: any) => {
		setSelectedIndexForMobile(event.getAttribute('data-key'));
	};

	const getDesktopView = () => {
		if (loadingState.isLoading) return <Loader />;
		return (
			<div className="desktop-view">
				<Heading content={`Statistics for ${currentDate}`} />
				<Table
					columns={StatisticsColumns}
					data={statisticsTableData}
					isSortable={true}
					sortingInfo={sortingInfo}
					sortDataHandler={sortDataHandler}
				/>
			</div>
		);
	};

	const getMobileView = () => {
		if (loadingState.isLoading) return <Loader />;
		return (
			<div className="mobile-view">
				<div className="dropdown-container">
					<SingleSelect
						data={StatisticsIndicesDropdownConstants}
						changeHandler={handleIndexChange}
						dataTestId={'drpStatisticsIndex'}
					/>
				</div>
				<Table
					columns={StatisticsMobileViewColumns}
					data={statisticsMobileTableData}
					hideHeader={true}
				/>
			</div>
		);
	};

	return (
		<div id="statistics-container">
			{isMobileView && getMobileView()}
			{!isMobileView && (
				<Container
					applyPadding
					applyBorder
					applyWhiteBackground
					hasLoader={loadingState.isLoading}
				>
					{getDesktopView()}
				</Container>
			)}
		</div>
	);
};

export default Statistics;
